import { cva } from "class-variance-authority";
import { FC, ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { TextBad } from "@/components/text";
import { onExitDialog } from "@/features/onboarding/components/exit-dialog";
import { fromDepositState } from "@/features/payment/deposit/components/verification";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";

import { Header } from "./components/Header/Header";
import { NavigationItem } from "./components/navigation/navigation.helpers";
import { ProgressBar } from "./components/navigation/progress-bar";

const wrapperStyles = cva("grid items-start md:px-4", {
  variants: {
    hasImage: {
      true: "lg:grid-cols-[60%_1fr]",
    },
  },
});

type Props = {
  children: ReactNode;
  title: string;
  image?: string;
  description?: string;
  activeStep: string;
  setStep: (step: string) => void;
  navigation: NavigationItem[];
};

export const OnboardingContainer: FC<Props> = ({
  children,
  title,
  description,
  image,
  activeStep,
  setStep,
  navigation,
}) => {
  const hasImage = !!image;
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const { open: openTerminal } = useTerminalAutoRoute();
  const { state } = useLocation();

  const [exitCount, setExitCount] = useState(1);
  const isFromDeposit = state === fromDepositState;

  const currentStepIndex = useMemo(
    () => navigation.findIndex(item => item.id.toLowerCase() === activeStep),
    [activeStep, navigation],
  );

  const closeButtonAction = () => {
    if (!isFromDeposit) {
      track(amplitudeEvents.kyc.close(currentStepIndex + 1));
      openTerminal();
    } else if (exitCount < 2) {
      onExitDialog(true);
      setExitCount(prevState => prevState + 1);
    } else {
      track(amplitudeEvents.kyc.close(currentStepIndex + 1));
      navigate(-1);
    }
  };

  return (
    <div className={wrapperStyles({ hasImage })}>
      <main className="max-w-full lg:max-w-[600px]">
        <Header title={title} onClose={closeButtonAction} />

        {description && (
          <TextBad className="mt-4 text-[14px] md:text-[16px]" family="roboto" color="dimmed">
            {description}
          </TextBad>
        )}
        {isMobile && <ProgressBar activeStep={activeStep} setStep={setStep} navigation={navigation} />}
        <div className="md:my-[25px]">{children}</div>
      </main>
      {hasImage && (
        <div className="hidden lg:grid lg:place-items-end">
          <img alt="background" src={image} style={{ width: 190, height: 320 }} />
        </div>
      )}
    </div>
  );
};
