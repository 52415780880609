import { useStore } from "@nanostores/react";

import { $exitDialogOpen, ExitOnboardingDialog, onExitDialog } from "@/features/onboarding/components/exit-dialog";
import { SumsubContainer } from "@/features/onboarding/components/sumsub/sumsub.container";
import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileHeaderQuery } from "@/state/server/profile";

export const SumsubPage = () => {
  const { data: kycInfo } = useKycInfoQuery();
  const { data: profileHeader } = useProfileHeaderQuery();
  const exitDialogOpen = useStore($exitDialogOpen);

  if (!profileHeader || !kycInfo) return null;

  return (
    <>
      <SumsubContainer sumSubInfo={kycInfo.sumSub!} email={profileHeader.email!} />
      <ExitOnboardingDialog open={exitDialogOpen} onOpenChange={onExitDialog} />
    </>
  );
};
